@include sticky-footer($height: 54px);

.main-footer {
  height: 300px;
  color: $theme-white;
  background-color: $theme-grey;
  font-size: rem(24px);
  text-transform: uppercase;
  text-align: center;
  max-width:1260px;
  width: 91%;
  margin: -54px auto;
  letter-spacing: 0.7px;

  .wrapper {
    max-width: 1060px;
    width: 91%;
    //margin: 0 #{($wrapperMargin) * 1%};
  }

  .wrapper:first-of-type {
  	background-color: $theme-purple;
  	text-align:left;
  	padding-left: 70px;
  	padding-right: 70px;
  	padding-top: 16px;
  	padding-bottom: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width:em(1100px)){
      flex-wrap: wrap;
      justify-content: center;
    }

  	@media screen and (max-width:em(820px)){
  		text-align: center !important;
      padding-left: 0;
      padding-right: 0;
  	}
  }

  @media screen and (max-width:em($mobile-menu-switch-max)){
    width: 100%;

    .wrapper {
      width:100%;
      margin:0 auto;
    }
  }

  div {
  	display: inline-block;
  }

  li {
  	list-style: none;
  	display: inline;
  }

  ul {
  	display: inline;
  }

  a {
  	color: $theme-white;
  	text-decoration: none;
  }

  @media screen and (max-width:em(442px)){
  	height: 500px;
  }
}

.copyright {
	font-size: rem(16px);
}

