main {
margin-top: -208px;
color: $theme-white;

	@media screen and (max-width:em(768px)){
		.wrapper {
			width:100%;
			margin:0px;
		}
	}
}