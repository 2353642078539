// Remove the unit of a length
//
// @param $number - Number to remove unit from

@function parseInt($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}


// Converts pixel unit into an EM unit.
//
// @param  $target Font size of target element in pixels
// @param  $parent Font size of parent element in pixels
//
// Example: em(10px, 20px);

@function em($target, $parent: 16px) {
  // strips px unit to return only the value
  $targetVal : $target / ($target * 0 + 1);
  $parentVal : $parent / ($parent * 0 + 1);

  @return #{($targetVal / $parentVal)}em;
}


// Converts pixel unit into an REM unit.
//
// @param  $px Pixel unit
//
// Example: rem(16px);

@function rem($px) {
  // $pxBase : 16;

  // strips px unit to return only the value
  $pxVal : $px / ($px * 0 + 1);

  @return #{($pxVal / $pxBase)}rem;
}


// Calculates percentage value of target to container
//
// @param  $target    Size of target element to be calculated
// @param  $container Size of container element to be calculated from
//
// Example: calc-percent(500px, 1000px);

@function percent($target, $container) {
  // strips px unit to return only the value
  $targetVal    : $target / ($target * 0 + 1);
  $containerVal : $container / ($container * 0 + 1);

  @return #{($targetVal / $containerVal) * 100%};
}
