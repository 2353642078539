.top_notification {
	background: $theme-purple;
	color: white;
	text-align: center;
	font-size: em(21.5px);
	line-height: 1;
	letter-spacing: 1.1px;
	padding: 5px;

	p {
		display: inline;
		text-transform: uppercase;
	}

	a {
		cursor: pointer;
		text-decoration: underline;
	}
}