.department-grid-boxes {

	@media screen and (max-width:em(1180px)){
		text-align:center;
	}

	.box-content {
		padding: 14px;

		@media screen and (max-width:em(1185px)){
			max-width: 50%;
		}

		@media screen and (max-width:em($mobile-menu-switch-max)){
			max-width: 80%;
		}
	}

	.box-btn {
		position: static;
		display:inline-block;
		margin-bottom: 10px;
		text-align:center;
		background: url('../../images/blue-btn.svg');
	}

	.box-number {
		font-size: em(96px);
		line-height:1;
	}

	.box-list {
		list-style: none;
		text-transform: uppercase;
		color: $theme-tan;
		font-family: $font-accent;

		li:before{
			color: $theme-white;
			content: '• ';
		}
	}
}