.black-box {
	margin: 65px 10px 0;
	padding: 0px; 
	font-family: $font-main;
	@include fluid-text($font-min : 20px, $viewport-min : 320px, $font-max : 36px, $viewport-max : 900px);
	color: $theme-white;
	background: $theme-black;
	text-align: center;
	text-transform:uppercase;
	display: block;
}