.content-header{
	display: block;
	text-align:center;

	h1 {
		float: left;

		@media screen and (max-width:em($mobile-menu-switch-max)){
			float: none;
		}
	}

	.sfsi_widget {
		float: right;
		margin-right: 141px;

		@media screen and (max-width:em($mobile-menu-switch-max)){
			float: none;
			margin-right: 0;
			width: 130px;
			display: inline-block;

			div:first-child {
				margin-left: 0 !important;
			}
		}
	}

	& ~ * {
		clear: both;
	}
}

.social-list {
	display: inline-block;

	@media screen and (max-width:em(370px)){
		display: block !important;
	}

	li {
		margin-left: 5px;
		@media screen and (max-width:em(370px)){
			margin: 0 5px;
		}
	}
}