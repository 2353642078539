.banner-wrapper {
		display: inline-block;
		vertical-align: middle;
		margin: 10px;
		white-space:nowrap;

		@media screen and (max-width:em($mobile-menu-switch-min)){
			display:none;
		}

		&:before {
			content:'';
			display: inline-block;
			vertical-align: middle;
			width:0;
			height:0;
			border-bottom:22px solid $theme-white;
			border-left:12px solid transparent;
			border-top:22px solid $theme-white;
			border-right:0px solid $theme-white;
		}

		&:after {
			content:'';
			display: inline-block;
			vertical-align: middle;
			width:0;
			height:0;
			border-bottom:22px solid $theme-white;
			border-right:12px solid transparent;
			border-top:22px solid $theme-white;
			border-left:0px solid $theme-white;
		}
}

.banner-headline {
	vertical-align: middle;
	display:inline;
	margin: 0 auto;
	padding: 7px;
	padding-left: 40px;
	padding-right: 40px;
	color: $theme-purple;
	text-shadow: none;
	font-size: em(24px);
	letter-spacing: 1px;
	background: white;
	border-bottom:1px solid $theme-white;
}