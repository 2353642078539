.background-cover {
	width: 1260px;
	height: 100%;
	left: 50%;
	transform: translate(-50%);
	background: $theme-grey;
	position: absolute;

	@media screen and (max-width:em(1384px)){
		width: 91%;
	}

	@media screen and (max-width:em(768px)){
		width:100%;
	}
}

.background-404 {
  width: 1260px;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  background: $theme-black;
  position: absolute;
  z-index: -1;
}