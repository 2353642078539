nav.footer-menu {
	margin-top: 100px;
	@media screen and (max-width:em(629)){
  	margin-top: 50px;
  }
}

ul.footer-menu {
	li{
		letter-spacing: 1px;
		padding-left: 19px;
		padding-right: 19px;
		border-color: $theme-white;
		border-right: 1px solid $theme-white;
		border-right-width: 1px;
		font-size: em(16px);

		&:last-of-type{
			border: 0px;
		}

		&:nth-of-type(2){
			font-family: $font-script;
			text-transform: capitalize;
			a {
				color: $theme-tan;
			}
		}

		@media screen and (max-width:em(800px)){
			letter-spacing: 0px;
			padding-left: 10px;
			padding-right: 10px;
  	}

  	@media screen and (max-width:em(629)){
  		display:block;
  		border: 0px;
  	}
	}
}