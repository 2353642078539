.slide-content {
	margin-top:-50px;
	text-transform: uppercase;
	text-align: center;
	text-shadow: 2px 2px 8px $theme-black;
	color: $theme-white;
	@include fluid-text($font-min : 20px, $viewport-min : 320px, $font-max : 38px, $viewport-max : 1080px);
	line-height: 1.4;
	position: absolute;
	width: 100%;
	height: auto;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);
	padding-left:140px;
	padding-right:140px;

	@media screen and (max-width:em($mobile-menu-switch-max)){
		padding: 0 50px;
	}

	.script-headline {
		font-size: em(48px, 38px);
		font-family: $font-script;
		text-transform: capitalize;
		line-height: 1;
	}	

	.main-headline {
		font-size: em(72px, 38px);
		letter-spacing: 1px;
		margin-bottom: 5px;
		line-height: 1;
	}

	.sub-headline {
		@include fluid-text($font-min : 20px, $viewport-min : 320px, $font-max : 18px, $viewport-max : 1080px);
		font-family: $font-accent;
		text-transform: none;
		max-width: 80%;
		margin: 0 auto;

		@media screen and (max-width:em($mobile-menu-switch-max)){
			max-width: 100%;
		}
	}

	.banner-headline {
		vertical-align: middle;
		display:inline;
		margin: 0 auto;
		padding: 7px;
		padding-left: 40px;
		padding-right: 40px;
		color: $theme-purple;
		text-shadow: none;
		font-size: em(24px, 38px);
		letter-spacing: 1px;
		background: white;
		border-bottom:1px solid $theme-white;
	}

	.call-to-action {
		display:inline-block;
		padding-left: 24px;
		padding-right: 24px;
		padding-top: 5px;
		padding-bottom:5px;
		border-radius:10px;
		font-size:rem(18px);
		font-family: $font-accent;
		text-transform: none;
		background: $theme-purple;
		text-shadow: none;
		transition: background 0.2s, color 0.2s;
		margin-top: 5px;

		@media screen and (max-width:em(622px)){
			padding: 12px;
		}

	}

	a {
		color: $theme-white; 
		text-decoration: none;

		&:hover {
			color: $theme-purple;
			background: $theme-white;
		}
	}
}

.slide-image {
	height: 550px;
	background-size: cover;
	background-position: center;
	width: $wrapperWidth;
	box-shadow: inset 0 -70px 100px 0px $theme-grey;
}