.embed_pdf,
.embed_iframe {
	padding-bottom: 56.25%;
	position: relative;
	padding-top: 30px; 
	height: 842px; // assuming A4 page, 72 dpi
	overflow: hidden;

	object,
	iframe {
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 100%;
		border:0;
	}
}

.embed_iframe {
	height: 1300px ;
}