.main-header {
  z-index:5;
	position: relative !important;
	height: 92px;
  top:auto !important;
  //background-color: rgba($main-header-background-color, 0.8);
  overflow:visible !important;

  @media screen and (max-width:em($mobile-menu-switch-max)){
		background: $theme-black;
    height: auto;
	}

  @media screen and (max-width:em(460px)){
    height: auto;
  }
  .wrapper {
  	height:inherit;
  }
}