.accordion-menu {
	font-size: em(24px);
	box-sizing: border-box;
	background: $theme-black;
	padding: 0 70px;
	margin-bottom: -10px;

	@media screen and (max-width:em($mobile-menu-switch-max)){
	padding: 0 10px;
	}

	@media screen and (max-width:em(650px)){
		margin: 0 10px;
	}

	a {
		color: $theme-purple;
	}

	ul,
	li {
		list-style: none;
	}

	label {
		cursor: pointer;
		display: block;
	}

	input + label:before {
		padding: 0 10px;
		position: relative;
		top: 4px;
		font-size: 175%;
		line-height: 1;
		font-family: $font-accent;
		content: '+';
	}
	input:checked + label:before {
		content: '-';
		padding: 0 14px;
	}

  p {
  	margin-bottom: 15px;
  	line-height: 1.5;
  }
  p:last-of-type {
  	margin: 0;
  }

	.accordion-top-group {
		background: $theme-purple;
		display:block;
		padding: 5px;
		margin-bottom: 10px;
		font-family: $font-accent;
		font-size: em(12px);
		color: $theme-white;
	}

	.accordion-sub-group {
		font-size: em(11px);
		line-height: 2.5;
		margin-bottom: 10px;
		background: $theme-md-grey;
		color: $theme-tan;
		font-family: $font-accent-thin;
	}

	.accordion-body {
		background: $theme-lt-grey;
		font-family: $font-accent-thin;
		font-size: em(14px);
		padding: 30px 50px;
		margin-bottom: 10px;
		color: $theme-grey;

		ul,
		ol,
		li,
		ul li,
		ol li {
			display: inherit;
			list-style-type:	initial !important;
		}
	}

	//everything below here makes the accordion work
	input[type=checkbox] {
	  /* hide native checkbox */
	  position: absolute;
	  opacity: 0;
	}

	ul {
	  /* by default hide all sub menus */
	  display: none;
	}

	input[type=checkbox]:checked + label + ul,
	input[type=checkbox]:checked + label:nth-of-type(n) + ul {
	  /* show children when item is checked */
	  display: block;
	}

}