*,
*:before,
*:after {
  margin:0;
  padding:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.xdebug-var-dump {
  background: white !important;
  color: black !important;
}

html,
body {
  height:100%;
  position: relative;
}

body {
  font: {
    family: $font-main;
    size: 100%;
    weight: normal;
  }
  line-height: (26/16); // Golden Ratio scale
  color: $font-base-color;
  background: url('../../images/background-texture.jpg');
  background-color: $body-background-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

.wrapper {
  position: relative;
  width: #{100 - ($wrapperMargin * 2) * 1%};
  margin: 0 #{($wrapperMargin) * 1%};

  @media screen and (min-width: em($fluidWidth)) {
    width: em($wrapperWidth);
    margin: 0 auto;
  }

  @media screen and (max-width: em(1285px)){
    max-width:1260px;
    width: 91%;
    margin: 0 auto;
  }
}

a {
  text-decoration: none;
  color: $theme-white;
}