.top-menu {
  list-style: none;
  position: absolute;
  top: 0px;
  right: 0px;
  font-family: $font-main;
  width:414px;
  height:34px;
  text-transform: uppercase;
  text-align:left;
  background: url('../../images/top-menu-background.svg');

  ul {
    padding-left: 33px;
  }

  li {
    display:inline;
    position: relative; 
    top: 15%;
    display: inline;
    font-size:em(16px);
    letter-spacing: 1.7px;

    &:before {
      content: '|';
      color: darken($theme-tan, 40%);
      margin-right: 8px;
      margin-left: 7px;
    }

    &:first-of-type{
      font-family: $font-script;
      text-transform: capitalize;
      letter-spacing: 0.2px;
      font-size: em(14.4px);

      &:before {
        content: '';
      }
    }

    &:last-of-type {
      border:0px;
    }

  }

  a {
    color: $theme-tan;
    text-decoration: none;

    &:hover {
      color:$theme-white;
    }

  }

  @media screen and (max-width: em($mobile-menu-switch-max)){
    display: none;
    font-size:rem(12px);
    background:none;
    height: auto;
    width: 100%;
    text-align: right;
    margin:0 auto;
  }
  
}