.weekly-specials-form {
	box-sizing: border-box;
	background: $theme-black;
	margin-top: 40px;
	margin-bottom: 40px;
	padding: em(50px);

	label {
		color: $theme-purple;
		font-family: $font-main;
		font-size: em(36px);
		text-transform: uppercase;
	}

	.gform_heading {
		display: inline-block;
		float: left;
		width: 32%;
	}
	.gform_body {
		display: inline-block;
		width: 32%;

		.gform_fields {
			li {
				display: inline-block;
				// margin: 0 10px 10px 10px;
				width: 100%;
			}

			li:nth-of-type(2) {
				float: right;
			}
		}
	}
	.gform_footer {
		margin-left: 21px;
		display: inline-block;
		width: 32%;
		position: relative;
		top: 1px;

		input {
			width: 100%;
		}
	}

	@media screen and (max-width:em(1035px)){
		padding: 10px;

		.gform_heading, 
		.gform_body {
			width: 100%;
		}
		.gform_footer {
			text-align: left;
		}

		.gform_footer {
			margin: 10px 0 0 0;
		}
		.gform_footer input {
			width: 100%;
		}
	}

	@media screen and (max-width:em(600px)){
		.gform_fields li:last-of-type { float: none; }
		.gform_fields li { 
			width: 100% !important; 
			margin: 0 !important;
		}
	}
}