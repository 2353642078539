.advantage-rewards-form {
	box-sizing: border-box;
	position: relative;
	background: $theme-black;
	margin: 40px 0;
	padding: 50px 0;
	overflow: auto;

	.gform_wrapper {
		width: 91%;
		margin: 0 auto;

		@media screen and (max-width:em($mobile-menu-switch-max)){
			width: 100%;
		}
	}

	small {
		font-family: $font-accent-thin;
		float: right;
		clear: both;
		margin-top:10px;
		margin-right: 64px;
		@media screen and (max-width:em($mobile-menu-switch-max)){
			margin-right: 10px;
		}
	}

	.gform_heading {
		padding-left: 10px;
	}

	.gform_footer{
		float:right;
		width: 35%;
		margin-right:10px;
	}

	.gform_fields {
		display: flex;
		flex-wrap: wrap;
	}

	label {
		display: none;
	}

	li {
		padding: 10px;
	}

	li:first-of-type { //first/last
		width: 50%;
		span {
			display: inline-block;
			width: 50%;
		}
		span:first-of-type {
			float: left;
			padding-right: 10px;
		}
		span:last-of-type {
			float: right;
			padding-left: 10px;
		}
	}

	li:nth-of-type(2){ //email
		width: 50%;
	}

	li:nth-of-type(3){ //street
		width:50%;
	}
	li:nth-of-type(4){ //phone
		width: 50%;
	}

	li:nth-of-type(5){ //city
		width: 25%;
	}

	li:nth-of-type(6){ //zip
		width: 25%;
	}

	li:nth-of-type(7){ //contact method
		width: 50%;
	}

	li:nth-of-type(8){ //newslettter
		width: 50%;
	}

	li:nth-of-type(8){
		width: 50%;
		font-family: $font-accent-thin;
		div {
			display: inline-block;
			margin: 0 10px;
		}

		.ginput_container {
			float: right;
			width: 15%;
			padding: 0;
			margin: 0;
			li {
				padding: 0;
				margin: 10px 0 0 0;
				width: auto;
			}
		}
		.gfield_description {
			float: left;
			width: 60%;
		}
	}

input[type=checkbox] {
			cursor: pointer;
			-webkit-appearance: none;
			appearance: none;
			background: $theme-white;
			border-radius: 5px;
			box-sizing: border-box;
			position: relative;
			width: 30px;
			height: 30px;
			border-width: 0;
			float: right;

			&:checked {
 				background: url('../../images/icon-check.svg') no-repeat;
 				background-size: 25px;
 				background-color: $theme-white;
 				background-position: 3px 6px;
			}

			&:focus{
				outline: 0 none;
  			box-shadow: none;
			}
		}

	@media screen and (max-width:em(680px)){
		li:nth-of-type(1){ //first/last
			width: 100%;
			order: 1;
		}
		li:nth-of-type(2){ //email
			width: 50%;
			order: 2;
		}
		li:nth-of-type(3){ //street
			width: 100%;
			order: 4;
		}
		li:nth-of-type(4){ //phone
			width: 50%;
			order: 3;
		}

		li:nth-of-type(5){ //city
			width: 50%;
			order: 5;
		}

		li:nth-of-type(6){ //zip
			width: 50%;
			order: 4;
		}
		li:nth-of-type(7){ //contact method
			width: 50%;
			order: 7;
		}
		li:nth-of-type(8){ //newsletter
			width: 100%;
			order: 8;
		}
		li:nth-of-type(9){
			width: 50%;
			order: 9;
		}
		li:nth-of-type(10){
			width: 50%;
			order: 10;
		}
	}
}	