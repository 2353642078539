.hero-carousel {	
	.slide-cover {
		height: 50px;
		background-color: $theme-black;
		width: 100%;
	}

	.wrapper {
		position: absolute;
		top: 0px;
		width:100%;
		background: rgba($theme-black, 0.35);
		height:100%;

		@media screen and (max-width:rem(1270px)){
			margin: 0px;
		}

	}

}

.bx-wrapper {
	max-width:1260px !important;
	width: 91%;
	margin: 0 auto;

	@media screen and (max-width:rem($mobile-menu-switch-max)){
		width:100%;
		// top:60px;
	}
}

.bx-viewport {
	height: 523px !important;

	.slide {
		height: 523px;
	}
}