button {
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

%form-button {
	//used in department forms
	background: linear-gradient(lighten($theme-purple, 10%), $theme-purple);
	border: 0;
	color: $theme-white;
	cursor: pointer;
	box-sizing: border-box;
	padding: 10px;
	text-transform: uppercase;
	font-size: em(18px);
	font-family: $font-accent;

	&:hover {
		background: linear-gradient($theme-purple, lighten($theme-purple, 10%));
	}
}