.department-form {
	text-align: center;
	color: $theme-white;
	background: $theme-black;
	padding:80px 40px;
	display: inline-block;
	width: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);

	.gform_description {
		font-size: em(14px);
		font-family: $font-accent;
	}

	.gfield_label {
		display: none;
	}

	.gform_fields {
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		list-style: none;
		height: 225px;
		width: 80%;
		margin: 0 auto;

		@media screen and (max-width:em($mobile-menu-switch-max)){
			width: 100%;
		}

		@media screen and (max-width:em(590px)){
			height: auto;
		}
	}

	.gform_footer {
		width: 30%;
		float:right;
		margin-right: 11%;
		@media screen and (max-width:em($mobile-menu-switch-max)){
			margin-right: 10px;
			width: 37%;
		}
	}

	li:first-of-type {
		width:60%;
	}

	li {
		width: 40%;
		padding: 8px;
		text-transform: uppercase;
		font-size: em(18px);
		font-family: $font-accent;
		@media screen and (max-width:em(590px)){
			width:100% !important;
		}
	}
}