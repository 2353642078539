// imported theme fonts
// @font-face font imports from sass/fonts
// ---------------------------------

// @import "../fonts/font-font-name";
@import "../fonts/font-league-gothic";
@import "../fonts/font-droid-sans";
@import "../fonts/font-droid-sans-regular";
@import "../fonts/font-thirsty";

// Theme brand colors
// ---------------------------------

$theme-blue  	   : #0088cc;
$theme-black 	   : #000000;
$theme-grey  	   : #0a0a0a;
$theme-md-grey   : #1a1a1a;
$theme-lt-grey	 : #cccccc;
$theme-white  	 : #FFFFFF;
$theme-purple 	 : #87529b;
$theme-tan    	 : #c2b59b;


// Theme specific variables
// ---------------------------------

$body-background-color : $theme-black;

$main-header-background-color : $theme-black;
$wrapperWidth  : 1080; // px value


//$font-main   : 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-main   			: 'league-gothic';
$font-script 			: 'thirsty';
$font-accent 			: 'droid-sans';
$font-accent-thin : 'droid-sans-regular';


$font-base-color : $theme-black;
$link-color      : $theme-purple;

$mobile-header-height: 100px;

$mobile-menu-button-color: $theme-purple;
// $footer-background-color : transparent;
