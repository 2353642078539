.page-hero {
	position: relative;
}

.bx-pager {
	a {
		background: none;
		border: 2px solid $theme-white;
		width:9px;
		height: 9px;
	}

	a.active {
		background: $theme-white;
		border: 2px solid $theme-white;
	}
}

.bxcontrol-wrapper {
    z-index: 5;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1080px;
    background: rgba(0,255,0,0.5);

    @media screen and (max-width: em($mobile-menu-switch-max)){
    	top: 50%;
    }
}

.bx-next,
.bx-prev {
	// font-size: em(60px);
	color: transparent;
	display: block;
	position: absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
}

.slider-next,
.slider-prev {
	position: absolute;
	display: block;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0.5;

	svg {
		width: 23px;
		height: auto;
	}
}

.slider-next {
	right: 0;

	svg{
		transform: rotate(180deg);
	}
}


.bx-next {
	@media screen and (max-width:em($mobile-menu-switch-max)){
		margin-right: -35px;
	}
}

.bx-prev {
	@media screen and (max-width:em($mobile-menu-switch-max)){
		margin-left:-35px;
	}	
}

.bx-default-pager {
	z-index: 2;
  margin-top: -175px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;

  @media screen and (max-width:em($mobile-menu-switch-max)){
  	margin-top: -100px;
  }
}