.grid-boxes {
	@include fluid-grid($list: true);
	color: $theme-white;
	margin: 0 0 138px 0;
	list-style: none;
	text-align: justify;
	font-family: $font-main;

	@media screen and (max-width:em(1186px)){
		text-align:center;

		.box{
			width: 90%;
		}

		.box-image,
		.box-cover {
			width:100%;
		}
	}

	@media screen and (max-width:em($mobile-menu-switch-max)){
		text-align:justify;
		.box {
			width: 100%;
			margin-left:0;
			margin-right:0;
		}
	}
}

.box {
	text-align: center;
	position:relative;
	height:100%;
	margin: 8px;

	&:hover {
		.box-cta {
			color: $theme-white;
			background: $theme-purple;
		}

		.box-cta-fix {
			color: $theme-white;
		}

		.box-image-cover {
			background: rgba($theme-black, 0.10);
		}
	}
}

.box-content {
	position: absolute;
	width: 100%;
	height: auto;
	top: 50%;
	left:50%;
	transform: translate(-50%, -50%);
	text-shadow: 2px 2px 8px $theme-black;

	@media screen and (max-width:em(1185px)){
		max-width: 50%;
	}

	@media screen and (max-width:em($mobile-menu-switch-max)){
		max-width: 80%;
	}
}

.box-image{
	width: em(340px);
	height: em(320px);
	background-size: cover;
	background-position: center;
}

.box-image-cover {
	width:100%;
	height:100%;
	background: rgba($theme-black, 0.35);
	position:absolute;
	top:0px;
	transition: background-color 0.5s ease;
}

.box-btn {
	position: absolute;
	top: 20px;
	left: 20px;
	background: url('../../images/btn.svg');
	height: 53px;
	width: 53px;
	font-family: $font-script;
	padding-top:16px;
}

.box-pre-headline,
.box-sub-text {
	font-size: em(14px);
	text-transform: uppercase;
	letter-spacing: 0px;
	margin: 0;
	font-family: $font-accent;
}

.box-main-headline {
	font-size: em(48px);
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 1;
}

.box-banner {
	text-transform: uppercase;
}

.box-script {
	font-size: em(36px);
	font-family: $font-script;
	text-shadow: 2px 2px 8px $theme-black;
}

.box-cta {
	position: absolute;
	font-size: em(28px);
	line-height: 1;
	padding: 15px 0;
	text-transform: uppercase;
	color: $theme-purple;
	background: $theme-white;
	bottom: 0px;
	width: 100%;
	transition: background 0.2s, color 0.2s;
}

.box-cta-fix {
	color: $theme-purple;

	&:hover {
		color: $theme-white;
	}
}

.box:nth-of-type(2) .box-image-cover { display: none; }