.offering-listings {
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	justify-content: space-between;

	li {
		width: 20%;
		margin: 10px;

		@media screen and (max-width:em(968px)){
			width: 40%;
		}
		@media screen and (max-width:em(500px)){
			width: 90%;
		}
	}
}

.offering-headline {
	font-family: $font-main;
	color: $theme-purple;
	font-size: em(36px);
}

.offering-image {
	width: 100%;
	min-height: 170px;
	margin-bottom: 30px;
	background-size: cover;
}

.offering-list {
	color: $theme-white;
	font-family: $font-accent-thin;
	font-size: em(16px);
	text-align: left;
	li {
		width: 100%;
		position: relative;
		margin: 5px;

		&:before {
			content: "";
			width: 10px; 
			height: 10px; 
			background: $theme-purple;
			border-radius: 100px;
			display: block;
			position: absolute;
  	  left: -15px;
 	    top: 8px;
 		}
	}
}