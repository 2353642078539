ol {
	list-style: auto;
}

ul {
	list-style: none;
}

.advantage-rewards-list {
  margin-left: 0;
  padding-right: 0;
  list-style-type: none;
  position: relative;
	
  p {
  	display: inline-block;
  	font-family: $font-accent-thin;

  	&:first-line{
  		font-family: $font-accent;
  	}
  }

	li {
		counter-increment: step-counter;
		margin-bottom: 10px;

		&:before {
	    content: counter(step-counter);
	    font-family: $font-main;
	    display: inline-block;
	    position: relative;
	    top: -12px;
	    width: 33px;
	    height: 33px;
	    font-size: em(24px);
	    line-height: 1.4;
	    margin-right: 15px;
	    background-color: $theme-purple;
	    color: white;
	    padding: 0 0 0 13px;
	    border-radius: 100px;
		}
	}
}