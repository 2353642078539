@charset "UTF-8";
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: bold;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

@font-face {
  font-family: 'league-gothic';
  src: url("../../fonts/league-gothic.woff2") format("woff2"), url("../../fonts/league-gothic.woff") format("woff"), url("../../fonts/league-gothic.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'droid-sans';
  src: url("../../fonts/droid-sans.woff2") format("woff2"), url("../../fonts/droid-sans.woff") format("woff"), url("../../fonts/droid-sans.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'droid-sans-regular';
  src: url("../../fonts/droid-sans-regular.woff2") format("woff2"), url("../../fonts/droid-sans-regular.woff") format("woff"), url("../../fonts/droid-sans-regular.eof") format("eof"), url("../../fonts/droid-sans-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'thirsty';
  src: url("../../fonts/thirsty.woff2") format("woff2"), url("../../fonts/thirsty.woff") format("woff"), url("../../fonts/thirsty.ttf") format("ttf"), url("../../fonts/thirsty.eot") format("eot");
  font-weight: normal;
  font-style: normal;
}

img[src*=svg] {
  width: 100%\9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*=svg] {
    width: 100%;
  }
}

.mobile-menu-button {
  display: none;
}

.horizontal-scroll-menu:before, .content-editor blockquote:before, .content-editor:before, .horizontal-scroll-menu:after, .content-editor blockquote:after, .content-editor:after {
  content: ' ';
  display: table;
}

.horizontal-scroll-menu:after, .content-editor blockquote:after, .content-editor:after {
  clear: both;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.xdebug-var-dump {
  background: white !important;
  color: black !important;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  font-family: "league-gothic";
  font-size: 100%;
  font-weight: normal;
  line-height: 1.625;
  color: #000000;
  background: url("../../images/background-texture.jpg");
  background-color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

.wrapper {
  position: relative;
  width: 91.4666666667%;
  margin: 0 4.2666666667%;
}

@media screen and (min-width: 73.26em) {
  .wrapper {
    width: 67.5em;
    margin: 0 auto;
  }
}

@media screen and (max-width: 80.3125em) {
  .wrapper {
    max-width: 1260px;
    width: 91%;
    margin: 0 auto;
  }
}

a {
  text-decoration: none;
  color: #FFFFFF;
}

#field_1_7,
#field_2_12,
#field_3_6,
#field_4_3,
#field_5_3 {
  display: none;
}

.gform_title {
  font-family: "league-gothic";
  font-weight: normal;
  color: #87529b;
  text-transform: uppercase;
  font-size: 2.25em;
}

.gform_wrapper {
  font-family: "droid-sans";
}

.gform_wrapper textarea,
.gform_wrapper input,
.gform_wrapper select,
.gform_wrapper textarea {
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  text-transform: uppercase;
}

.gform_wrapper select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  color: #87529b;
  background: url(../../images/icon-drop-down.svg) no-repeat;
  background-position: 97%;
  background-size: 20px;
  background-color: #FFFFFF;
}

.gform_wrapper textarea {
  height: 263px;
}

.ui-datepicker {
  background: #FFFFFF;
  display: none;
  font-size: 1.25em;
  border: 2px solid #cccccc;
  border-radius: 5px;
  padding: 20px;
}

.ui-datepicker .ui-datepicker-next {
  float: right;
}

.ui-datepicker a {
  color: #87529b;
  text-decoration: none;
}

.ui-datepicker table * {
  margin: 5px;
}

.gform_confirmation_wrapper {
  text-align: center;
  font-size: 1.5em;
}

.link {
  box-shadow: 0 0 0 0 #87529b;
  color: #87529b;
  text-decoration: none;
  transition: color 0.1s ease, box-shadow 0.1s ease;
}

.link:hover, .link:focus {
  color: #5f396d;
  box-shadow: 0 1px 0 0 #5f396d;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.gform_wrapper input[type=submit] {
  background: linear-gradient(#9f6eb2, #87529b);
  border: 0;
  color: #FFFFFF;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  text-transform: uppercase;
  font-size: 1.125em;
  font-family: "droid-sans";
}

.gform_wrapper input[type=submit]:hover {
  background: linear-gradient(#87529b, #9f6eb2);
}

ol {
  list-style: auto;
}

ul {
  list-style: none;
}

.advantage-rewards-list {
  margin-left: 0;
  padding-right: 0;
  list-style-type: none;
  position: relative;
}

.advantage-rewards-list p {
  display: inline-block;
  font-family: "droid-sans-regular";
}

.advantage-rewards-list p:first-line {
  font-family: "droid-sans";
}

.advantage-rewards-list li {
  counter-increment: step-counter;
  margin-bottom: 10px;
}

.advantage-rewards-list li:before {
  content: counter(step-counter);
  font-family: "league-gothic";
  display: inline-block;
  position: relative;
  top: -12px;
  width: 33px;
  height: 33px;
  font-size: 1.5em;
  line-height: 1.4;
  margin-right: 15px;
  background-color: #87529b;
  color: white;
  padding: 0 0 0 13px;
  border-radius: 100px;
}

.page-headline {
  font-size: 1.5rem;
}

@media (min-width: 20em) and (max-width: 73.125em) {
  .page-headline {
    font-size: calc(1.5rem + (2.625 - 1.5) * (100vw - 20rem) / (73.125 - 20));
  }
}

@media (min-width: 73.125em) {
  .page-headline {
    font-size: 2.625rem;
  }
}

.main-header {
  z-index: 5;
  position: relative !important;
  height: 92px;
  top: auto !important;
  overflow: visible !important;
}

@media screen and (max-width: 48em) {
  .main-header {
    background: #000000;
    height: auto;
  }
}

@media screen and (max-width: 28.75em) {
  .main-header {
    height: auto;
  }
}

.main-header .wrapper {
  height: inherit;
}

main {
  margin-top: -208px;
  color: #FFFFFF;
}

@media screen and (max-width: 48em) {
  main .wrapper {
    width: 100%;
    margin: 0px;
  }
}

.hero-carousel .slide-cover {
  height: 50px;
  background-color: #000000;
  width: 100%;
}

.hero-carousel .wrapper {
  position: absolute;
  top: 0px;
  width: 100%;
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
}

@media screen and (max-width: 79.375rem) {
  .hero-carousel .wrapper {
    margin: 0px;
  }
}

.bx-wrapper {
  max-width: 1260px !important;
  width: 91%;
  margin: 0 auto;
}

@media screen and (max-width: 48rem) {
  .bx-wrapper {
    width: 100%;
  }
}

.bx-viewport {
  height: 523px !important;
}

.bx-viewport .slide {
  height: 523px;
}

.slide-content {
  margin-top: -50px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 2px 2px 8px #000000;
  color: #FFFFFF;
  font-size: 1.25rem;
  line-height: 1.4;
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 140px;
  padding-right: 140px;
}

@media (min-width: 20em) and (max-width: 67.5em) {
  .slide-content {
    font-size: calc(1.25rem + (2.375 - 1.25) * (100vw - 20rem) / (67.5 - 20));
  }
}

@media (min-width: 67.5em) {
  .slide-content {
    font-size: 2.375rem;
  }
}

@media screen and (max-width: 48em) {
  .slide-content {
    padding: 0 50px;
  }
}

.slide-content .script-headline {
  font-size: 1.2631578947em;
  font-family: "thirsty";
  text-transform: capitalize;
  line-height: 1;
}

.slide-content .main-headline {
  font-size: 1.8947368421em;
  letter-spacing: 1px;
  margin-bottom: 5px;
  line-height: 1;
}

.slide-content .sub-headline {
  font-size: 1.25rem;
  font-family: "droid-sans";
  text-transform: none;
  max-width: 80%;
  margin: 0 auto;
}

@media (min-width: 20em) and (max-width: 67.5em) {
  .slide-content .sub-headline {
    font-size: calc(1.25rem + (1.125 - 1.25) * (100vw - 20rem) / (67.5 - 20));
  }
}

@media (min-width: 67.5em) {
  .slide-content .sub-headline {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 48em) {
  .slide-content .sub-headline {
    max-width: 100%;
  }
}

.slide-content .banner-headline {
  vertical-align: middle;
  display: inline;
  margin: 0 auto;
  padding: 7px;
  padding-left: 40px;
  padding-right: 40px;
  color: #87529b;
  text-shadow: none;
  font-size: 0.6315789474em;
  letter-spacing: 1px;
  background: white;
  border-bottom: 1px solid #FFFFFF;
}

.slide-content .call-to-action {
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-size: 1.125rem;
  font-family: "droid-sans";
  text-transform: none;
  background: #87529b;
  text-shadow: none;
  transition: background 0.2s, color 0.2s;
  margin-top: 5px;
}

@media screen and (max-width: 38.875em) {
  .slide-content .call-to-action {
    padding: 12px;
  }
}

.slide-content a {
  color: #FFFFFF;
  text-decoration: none;
}

.slide-content a:hover {
  color: #87529b;
  background: #FFFFFF;
}

.slide-image {
  height: 550px;
  background-size: cover;
  background-position: center;
  width: 1080;
  box-shadow: inset 0 -70px 100px 0px #0a0a0a;
}

.sticky-footer-push {
  min-height: 100%;
  padding-bottom: 54px;
}

.main-footer {
  height: 54px;
  margin-top: -54px;
}

.main-footer {
  height: 300px;
  color: #FFFFFF;
  background-color: #0a0a0a;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
  max-width: 1260px;
  width: 91%;
  margin: -54px auto;
  letter-spacing: 0.7px;
}

.main-footer .wrapper {
  max-width: 1060px;
  width: 91%;
}

.main-footer .wrapper:first-of-type {
  background-color: #87529b;
  text-align: left;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 68.75em) {
  .main-footer .wrapper:first-of-type {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 51.25em) {
  .main-footer .wrapper:first-of-type {
    text-align: center !important;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 48em) {
  .main-footer {
    width: 100%;
  }
  .main-footer .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}

.main-footer div {
  display: inline-block;
}

.main-footer li {
  list-style: none;
  display: inline;
}

.main-footer ul {
  display: inline;
}

.main-footer a {
  color: #FFFFFF;
  text-decoration: none;
}

@media screen and (max-width: 27.625em) {
  .main-footer {
    height: 500px;
  }
}

.copyright {
  font-size: 1rem;
}

.top_notification {
  background: #87529b;
  color: white;
  text-align: center;
  font-size: 1.34375em;
  line-height: 1;
  letter-spacing: 1.1px;
  padding: 5px;
}

.top_notification p {
  display: inline;
  text-transform: uppercase;
}

.top_notification a {
  cursor: pointer;
  text-decoration: underline;
}

.top-menu {
  list-style: none;
  position: absolute;
  top: 0px;
  right: 0px;
  font-family: "league-gothic";
  width: 414px;
  height: 34px;
  text-transform: uppercase;
  text-align: left;
  background: url("../../images/top-menu-background.svg");
}

.top-menu ul {
  padding-left: 33px;
}

.top-menu li {
  display: inline;
  position: relative;
  top: 15%;
  display: inline;
  font-size: 1em;
  letter-spacing: 1.7px;
}

.top-menu li:before {
  content: '|';
  color: #5a4e37;
  margin-right: 8px;
  margin-left: 7px;
}

.top-menu li:first-of-type {
  font-family: "thirsty";
  text-transform: capitalize;
  letter-spacing: 0.2px;
  font-size: 0.9em;
}

.top-menu li:first-of-type:before {
  content: '';
}

.top-menu li:last-of-type {
  border: 0px;
}

.top-menu a {
  color: #c2b59b;
  text-decoration: none;
}

.top-menu a:hover {
  color: #FFFFFF;
}

@media screen and (max-width: 48em) {
  .top-menu {
    display: none;
    font-size: 0.75rem;
    background: none;
    height: auto;
    width: 100%;
    text-align: right;
    margin: 0 auto;
  }
}

.site-logo {
  display: inline-block;
  z-index: 2;
  margin-left: 11px;
  background: #000000;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 21px;
  padding-bottom: 33px;
  box-shadow: 0px 6px 16px 1px rgba(0, 0, 0, 0.5);
}

.site-logo .site-logo-sm {
  display: none;
}

.site-logo img[src*=svg] {
  width: 95px;
  height: 117px;
}

@media screen and (max-width: 48em) {
  .site-logo {
    box-shadow: none;
    padding: 5px 0 0 0;
  }
  .site-logo .site-logo-lg {
    display: none;
  }
  .site-logo .site-logo-sm {
    display: block;
  }
  .site-logo img[src*=svg] {
    height: 46px;
  }
}

.site-logo-spotlight {
  position: absolute;
  z-index: -1;
  top: 25px;
  left: 34px;
  height: 95px;
  width: 95px;
  border-radius: 100px;
  box-shadow: 4px 0px 144px 22px rgba(255, 255, 255, 0.5);
}

@media screen and (max-width: 48em) {
  .site-logo-spotlight {
    display: none;
  }
}

.email-form {
  float: right;
  display: flex;
  margin-left: 10px;
}

@media screen and (max-width: 35.625em) {
  .email-form {
    float: none;
    margin-left: none;
  }
}

.email-form form {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 51.25em) {
  .email-form form {
    display: block;
  }
  .email-form form > * {
    display: block;
  }
}

.email-form li {
  display: block;
}

.email-form input[type="text"] {
  border-radius: 50px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-family: "droid-sans";
  color: #87529b;
}

@media screen and (max-width: 35.625em) {
  .email-form input[type="text"] {
    margin-left: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.email-form .gform_heading {
  margin-right: 17px;
}

@media screen and (max-width: 51.25em) {
  .email-form .gform_heading {
    margin-right: 0;
  }
}

.email-form .gfield_checkbox {
  display: inline-block;
}

.email-form .gfield_checkbox input {
  width: auto;
  appearance: none;
  background: #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  width: 10px;
  height: 10px;
  border-width: 0;
}

.email-form .gfield_checkbox input:checked {
  background: url("../../images/icon-check.svg") no-repeat;
  background-size: 15px;
  background-color: #FFFFFF;
  background-position: 50%;
}

.email-form .gfield_checkbox li {
  display: inline-flex;
}

.email-form .gfield_checkbox li label {
  font-size: 0.5em;
  margin: 0 10px;
}

.email-form .gform_title {
  color: #FFFFFF;
  font-size: 1em;
}

.email-form .gfield_label,
.email-form .gform_footer {
  display: none;
}

.email-form .gform_body {
  position: relative;
  top: -5px;
}

.email-form .gform_body input[type="text"] {
  background: url("../../images/icon-email.svg") no-repeat scroll 55px 50%;
  background-size: 13px;
  background-color: #FFFFFF;
}

@media screen and (max-width: 35.625em) {
  .email-form .gform_body input[type="text"] {
    background: #FFFFFF;
    text-align: center;
  }
}

nav.footer-menu {
  margin-top: 100px;
}

@media screen and (max-width: 39.3125em) {
  nav.footer-menu {
    margin-top: 50px;
  }
}

ul.footer-menu li {
  letter-spacing: 1px;
  padding-left: 19px;
  padding-right: 19px;
  border-color: #FFFFFF;
  border-right: 1px solid #FFFFFF;
  border-right-width: 1px;
  font-size: 1em;
}

ul.footer-menu li:last-of-type {
  border: 0px;
}

ul.footer-menu li:nth-of-type(2) {
  font-family: "thirsty";
  text-transform: capitalize;
}

ul.footer-menu li:nth-of-type(2) a {
  color: #c2b59b;
}

@media screen and (max-width: 50em) {
  ul.footer-menu li {
    letter-spacing: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 39.3125em) {
  ul.footer-menu li {
    display: block;
    border: 0px;
  }
}

.black-box {
  margin: 65px 10px 0;
  padding: 0px;
  font-family: "league-gothic";
  font-size: 1.25rem;
  color: #FFFFFF;
  background: #000000;
  text-align: center;
  text-transform: uppercase;
  display: block;
}

@media (min-width: 20em) and (max-width: 56.25em) {
  .black-box {
    font-size: calc(1.25rem + (2.25 - 1.25) * (100vw - 20rem) / (56.25 - 20));
  }
}

@media (min-width: 56.25em) {
  .black-box {
    font-size: 2.25rem;
  }
}

.grid-boxes {
  font-size: 0.1px;
  text-align: justify;
  list-style: none;
  color: #FFFFFF;
  margin: 0 0 138px 0;
  list-style: none;
  text-align: justify;
  font-family: "league-gothic";
}

.grid-boxes:after {
  content: '';
  display: inline-block;
  width: 100%;
}

.grid-boxes > * {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  font-size: 1rem;
}

@media screen and (max-width: 74.125em) {
  .grid-boxes {
    text-align: center;
  }
  .grid-boxes .box {
    width: 90%;
  }
  .grid-boxes .box-image,
  .grid-boxes .box-cover {
    width: 100%;
  }
}

@media screen and (max-width: 48em) {
  .grid-boxes {
    text-align: justify;
  }
  .grid-boxes .box {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.box {
  text-align: center;
  position: relative;
  height: 100%;
  margin: 8px;
}

.box:hover .box-cta {
  color: #FFFFFF;
  background: #87529b;
}

.box:hover .box-cta-fix {
  color: #FFFFFF;
}

.box:hover .box-image-cover {
  background: rgba(0, 0, 0, 0.1);
}

.box-content {
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 2px 2px 8px #000000;
}

@media screen and (max-width: 74.0625em) {
  .box-content {
    max-width: 50%;
  }
}

@media screen and (max-width: 48em) {
  .box-content {
    max-width: 80%;
  }
}

.box-image {
  width: 21.25em;
  height: 20em;
  background-size: cover;
  background-position: center;
}

.box-image-cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  position: absolute;
  top: 0px;
  transition: background-color 0.5s ease;
}

.box-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  background: url("../../images/btn.svg");
  height: 53px;
  width: 53px;
  font-family: "thirsty";
  padding-top: 16px;
}

.box-pre-headline,
.box-sub-text {
  font-size: 0.875em;
  text-transform: uppercase;
  letter-spacing: 0px;
  margin: 0;
  font-family: "droid-sans";
}

.box-main-headline {
  font-size: 3em;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}

.box-banner {
  text-transform: uppercase;
}

.box-script {
  font-size: 2.25em;
  font-family: "thirsty";
  text-shadow: 2px 2px 8px #000000;
}

.box-cta {
  position: absolute;
  font-size: 1.75em;
  line-height: 1;
  padding: 15px 0;
  text-transform: uppercase;
  color: #87529b;
  background: #FFFFFF;
  bottom: 0px;
  width: 100%;
  transition: background 0.2s, color 0.2s;
}

.box-cta-fix {
  color: #87529b;
}

.box-cta-fix:hover {
  color: #FFFFFF;
}

.box:nth-of-type(2) .box-image-cover {
  display: none;
}

@media screen and (max-width: 73.75em) {
  .department-grid-boxes {
    text-align: center;
  }
}

.department-grid-boxes .box-content {
  padding: 14px;
}

@media screen and (max-width: 74.0625em) {
  .department-grid-boxes .box-content {
    max-width: 50%;
  }
}

@media screen and (max-width: 48em) {
  .department-grid-boxes .box-content {
    max-width: 80%;
  }
}

.department-grid-boxes .box-btn {
  position: static;
  display: inline-block;
  margin-bottom: 10px;
  text-align: center;
  background: url("../../images/blue-btn.svg");
}

.department-grid-boxes .box-number {
  font-size: 6em;
  line-height: 1;
}

.department-grid-boxes .box-list {
  list-style: none;
  text-transform: uppercase;
  color: #c2b59b;
  font-family: "droid-sans";
}

.department-grid-boxes .box-list li:before {
  color: #FFFFFF;
  content: '• ';
}

.banner-wrapper {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  white-space: nowrap;
}

@media screen and (max-width: 48.0625em) {
  .banner-wrapper {
    display: none;
  }
}

.banner-wrapper:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-bottom: 22px solid #FFFFFF;
  border-left: 12px solid transparent;
  border-top: 22px solid #FFFFFF;
  border-right: 0px solid #FFFFFF;
}

.banner-wrapper:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  border-bottom: 22px solid #FFFFFF;
  border-right: 12px solid transparent;
  border-top: 22px solid #FFFFFF;
  border-left: 0px solid #FFFFFF;
}

.banner-headline {
  vertical-align: middle;
  display: inline;
  margin: 0 auto;
  padding: 7px;
  padding-left: 40px;
  padding-right: 40px;
  color: #87529b;
  text-shadow: none;
  font-size: 1.5em;
  letter-spacing: 1px;
  background: white;
  border-bottom: 1px solid #FFFFFF;
}

.background-cover {
  width: 1260px;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  background: #0a0a0a;
  position: absolute;
}

@media screen and (max-width: 86.5em) {
  .background-cover {
    width: 91%;
  }
}

@media screen and (max-width: 48em) {
  .background-cover {
    width: 100%;
  }
}

.background-404 {
  width: 1260px;
  height: 100%;
  left: 50%;
  transform: translate(-50%);
  background: #000000;
  position: absolute;
  z-index: -1;
}

.department-content {
  color: #FFFFFF;
  background: #000000;
  font-family: "droid-sans-regular";
  margin-top: 50px;
  padding: 3.125em;
}

.department-content h1 {
  font-family: "league-gothic";
  text-transform: uppercase;
  color: #87529b;
  letter-spacing: 1px;
}

.department-content p {
  margin-bottom: 15px;
}

.department-content p:last-of-type {
  margin: 0;
}

.department-content a {
  color: #87529b;
}

.body-social-list {
  position: absolute;
  right: 74px;
  top: 60px;
}

.body-social-list svg {
  width: 20px;
  height: auto;
}

.body-social-list li {
  display: inline;
  margin: 5px;
}

.body-social-list a {
  color: #636363;
}

.standard-content {
  color: #FFFFFF;
  background: #000000;
  font-family: "droid-sans";
  margin-top: 50px;
  padding: 3.125em;
  overflow: auto;
}

.standard-content h1 {
  font-family: "league-gothic";
  text-transform: uppercase;
  color: #87529b;
  font-size: 2.25em;
  letter-spacing: 1px;
}

@media screen and (max-width: 48em) {
  .standard-content h1 {
    text-align: center;
  }
}

.standard-content p {
  margin-bottom: 15px;
  font-family: "droid-sans-regular";
}

.standard-content img {
  max-width: 100%;
  height: auto;
}

.contact-us-content .callouts {
  width: 50%;
}

@media screen and (max-width: 52.5em) {
  .contact-us-content .callouts {
    width: 100%;
  }
}

.contact-us-content .contact-callout {
  width: 47%;
  display: inline-block;
  margin-right: 10px;
}

.contact-us-content .contact-callout .title {
  display: block;
}

.contact-us-content .contact-callout p {
  width: 100%;
  position: static !important;
  padding: 0 !important;
}

@media screen and (max-width: 48em) {
  .contact-us-content .contact-callout {
    width: 100%;
  }
}

.contact-us-content p {
  width: 50%;
}

.contact-us-content p:last-of-type {
  float: right;
  position: relative;
  top: -180px;
  padding-left: 10px;
}

@media screen and (max-width: 52.5em) {
  .contact-us-content p:last-of-type {
    position: static;
    float: none;
    padding: 0;
  }
}

@media screen and (max-width: 52.5em) {
  .contact-us-content p {
    width: 100%;
  }
}

.contact-us-content img {
  width: 100%;
}

.contact-us-content .contact-us-form {
  top: -70px;
}

@media screen and (max-width: 52.5em) {
  .contact-us-content .contact-us-form {
    top: 0;
  }
}

.page-id-38 .department-form {
  margin-top: -200px;
}

@media screen and (max-width: 52.8125em) {
  .page-id-38 .department-form {
    margin-top: 0;
  }
}

.holiday-market-select-content p {
  display: block;
}

.holiday-market-select-content section {
  margin-bottom: 20px;
}

.holiday-market-select-content p:last-of-type {
  padding: 0;
  position: relative;
  top: -180px;
  float: right;
  right: 20%;
}

.holiday-market-select-content p:last-of-type img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 64.5625em) {
  .holiday-market-select-content p:last-of-type {
    right: 0;
  }
}

@media screen and (max-width: 52.8125em) {
  .holiday-market-select-content p:last-of-type {
    position: static;
    float: none;
  }
}

@media screen and (max-width: 52.8125em) {
  .holiday-market-select-content {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 48em) {
  .holiday-market-select-content p:nth-of-type(2),
  .holiday-market-select-content p:nth-of-type(3),
  .holiday-market-select-content p:last-of-type {
    width: 100%;
    text-align: center;
  }
}

.cubeside-content {
  position: relative;
  top: -392px;
  padding-top: 90px;
}

.content-404 {
  margin-top: 208px;
  padding: 70px;
  background: #000000;
}

.offering-listings {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
}

.offering-listings li {
  width: 20%;
  margin: 10px;
}

@media screen and (max-width: 60.5em) {
  .offering-listings li {
    width: 40%;
  }
}

@media screen and (max-width: 31.25em) {
  .offering-listings li {
    width: 90%;
  }
}

.offering-headline {
  font-family: "league-gothic";
  color: #87529b;
  font-size: 2.25em;
}

.offering-image {
  width: 100%;
  min-height: 170px;
  margin-bottom: 30px;
  background-size: cover;
}

.offering-list {
  color: #FFFFFF;
  font-family: "droid-sans-regular";
  font-size: 1em;
  text-align: left;
}

.offering-list li {
  width: 100%;
  position: relative;
  margin: 5px;
}

.offering-list li:before {
  content: "";
  width: 10px;
  height: 10px;
  background: #87529b;
  border-radius: 100px;
  display: block;
  position: absolute;
  left: -15px;
  top: 8px;
}

.department-form {
  text-align: center;
  color: #FFFFFF;
  background: #000000;
  padding: 80px 40px;
  display: inline-block;
  width: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.department-form .gform_description {
  font-size: 0.875em;
  font-family: "droid-sans";
}

.department-form .gfield_label {
  display: none;
}

.department-form .gform_fields {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  list-style: none;
  height: 225px;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 48em) {
  .department-form .gform_fields {
    width: 100%;
  }
}

@media screen and (max-width: 36.875em) {
  .department-form .gform_fields {
    height: auto;
  }
}

.department-form .gform_footer {
  width: 30%;
  float: right;
  margin-right: 11%;
}

@media screen and (max-width: 48em) {
  .department-form .gform_footer {
    margin-right: 10px;
    width: 37%;
  }
}

.department-form li:first-of-type {
  width: 60%;
}

.department-form li {
  width: 40%;
  padding: 8px;
  text-transform: uppercase;
  font-size: 1.125em;
  font-family: "droid-sans";
}

@media screen and (max-width: 36.875em) {
  .department-form li {
    width: 100% !important;
  }
}

.advantage-rewards-form {
  box-sizing: border-box;
  position: relative;
  background: #000000;
  margin: 40px 0;
  padding: 50px 0;
  overflow: auto;
}

.advantage-rewards-form .gform_wrapper {
  width: 91%;
  margin: 0 auto;
}

@media screen and (max-width: 48em) {
  .advantage-rewards-form .gform_wrapper {
    width: 100%;
  }
}

.advantage-rewards-form small {
  font-family: "droid-sans-regular";
  float: right;
  clear: both;
  margin-top: 10px;
  margin-right: 64px;
}

@media screen and (max-width: 48em) {
  .advantage-rewards-form small {
    margin-right: 10px;
  }
}

.advantage-rewards-form .gform_heading {
  padding-left: 10px;
}

.advantage-rewards-form .gform_footer {
  float: right;
  width: 35%;
  margin-right: 10px;
}

.advantage-rewards-form .gform_fields {
  display: flex;
  flex-wrap: wrap;
}

.advantage-rewards-form label {
  display: none;
}

.advantage-rewards-form li {
  padding: 10px;
}

.advantage-rewards-form li:first-of-type {
  width: 50%;
}

.advantage-rewards-form li:first-of-type span {
  display: inline-block;
  width: 50%;
}

.advantage-rewards-form li:first-of-type span:first-of-type {
  float: left;
  padding-right: 10px;
}

.advantage-rewards-form li:first-of-type span:last-of-type {
  float: right;
  padding-left: 10px;
}

.advantage-rewards-form li:nth-of-type(2) {
  width: 50%;
}

.advantage-rewards-form li:nth-of-type(3) {
  width: 50%;
}

.advantage-rewards-form li:nth-of-type(4) {
  width: 50%;
}

.advantage-rewards-form li:nth-of-type(5) {
  width: 25%;
}

.advantage-rewards-form li:nth-of-type(6) {
  width: 25%;
}

.advantage-rewards-form li:nth-of-type(7) {
  width: 50%;
}

.advantage-rewards-form li:nth-of-type(8) {
  width: 50%;
}

.advantage-rewards-form li:nth-of-type(8) {
  width: 50%;
  font-family: "droid-sans-regular";
}

.advantage-rewards-form li:nth-of-type(8) div {
  display: inline-block;
  margin: 0 10px;
}

.advantage-rewards-form li:nth-of-type(8) .ginput_container {
  float: right;
  width: 15%;
  padding: 0;
  margin: 0;
}

.advantage-rewards-form li:nth-of-type(8) .ginput_container li {
  padding: 0;
  margin: 10px 0 0 0;
  width: auto;
}

.advantage-rewards-form li:nth-of-type(8) .gfield_description {
  float: left;
  width: 60%;
}

.advantage-rewards-form input[type=checkbox] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background: #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
  position: relative;
  width: 30px;
  height: 30px;
  border-width: 0;
  float: right;
}

.advantage-rewards-form input[type=checkbox]:checked {
  background: url("../../images/icon-check.svg") no-repeat;
  background-size: 25px;
  background-color: #FFFFFF;
  background-position: 3px 6px;
}

.advantage-rewards-form input[type=checkbox]:focus {
  outline: 0 none;
  box-shadow: none;
}

@media screen and (max-width: 42.5em) {
  .advantage-rewards-form li:nth-of-type(1) {
    width: 100%;
    order: 1;
  }
  .advantage-rewards-form li:nth-of-type(2) {
    width: 50%;
    order: 2;
  }
  .advantage-rewards-form li:nth-of-type(3) {
    width: 100%;
    order: 4;
  }
  .advantage-rewards-form li:nth-of-type(4) {
    width: 50%;
    order: 3;
  }
  .advantage-rewards-form li:nth-of-type(5) {
    width: 50%;
    order: 5;
  }
  .advantage-rewards-form li:nth-of-type(6) {
    width: 50%;
    order: 4;
  }
  .advantage-rewards-form li:nth-of-type(7) {
    width: 50%;
    order: 7;
  }
  .advantage-rewards-form li:nth-of-type(8) {
    width: 100%;
    order: 8;
  }
  .advantage-rewards-form li:nth-of-type(9) {
    width: 50%;
    order: 9;
  }
  .advantage-rewards-form li:nth-of-type(10) {
    width: 50%;
    order: 10;
  }
}

.contact-us-form {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 5;
}

@media screen and (max-width: 48em) {
  .contact-us-form {
    width: 100%;
    float: none;
  }
}

.contact-us-form input,
.contact-us-form select,
.contact-us-form textarea {
  margin: 0 0 15px 0;
}

.contact-us-form textarea {
  height: 190px;
}

.contact-us-form .gform_title,
.contact-us-form .gform_description,
.contact-us-form label {
  display: none;
}

.contact-us-form .gform_footer {
  float: right;
  width: 50%;
  padding-left: 10px;
}

.contact-us-form li:first-of-type {
  width: 100%;
}

.contact-us-form li:first-of-type span {
  display: inline-block;
  width: 50%;
}

.contact-us-form li:first-of-type span:first-of-type {
  float: left;
  padding-right: 10px;
}

.contact-us-form li:first-of-type span:last-of-type {
  float: right;
  padding-left: 10px;
}

.contact-us-form li:first-of-type:after {
  content: '';
  display: block;
  clear: both;
}

.weekly-specials-form {
  box-sizing: border-box;
  background: #000000;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 3.125em;
}

.weekly-specials-form label {
  color: #87529b;
  font-family: "league-gothic";
  font-size: 2.25em;
  text-transform: uppercase;
}

.weekly-specials-form .gform_heading {
  display: inline-block;
  float: left;
  width: 32%;
}

.weekly-specials-form .gform_body {
  display: inline-block;
  width: 32%;
}

.weekly-specials-form .gform_body .gform_fields li {
  display: inline-block;
  width: 100%;
}

.weekly-specials-form .gform_body .gform_fields li:nth-of-type(2) {
  float: right;
}

.weekly-specials-form .gform_footer {
  margin-left: 21px;
  display: inline-block;
  width: 32%;
  position: relative;
  top: 1px;
}

.weekly-specials-form .gform_footer input {
  width: 100%;
}

@media screen and (max-width: 64.6875em) {
  .weekly-specials-form {
    padding: 10px;
  }
  .weekly-specials-form .gform_heading,
  .weekly-specials-form .gform_body {
    width: 100%;
  }
  .weekly-specials-form .gform_footer {
    text-align: left;
  }
  .weekly-specials-form .gform_footer {
    margin: 10px 0 0 0;
  }
  .weekly-specials-form .gform_footer input {
    width: 100%;
  }
}

@media screen and (max-width: 37.5em) {
  .weekly-specials-form .gform_fields li:last-of-type {
    float: none;
  }
  .weekly-specials-form .gform_fields li {
    width: 100% !important;
    margin: 0 !important;
  }
}

.accordion-menu {
  font-size: 1.5em;
  box-sizing: border-box;
  background: #000000;
  padding: 0 70px;
  margin-bottom: -10px;
}

@media screen and (max-width: 48em) {
  .accordion-menu {
    padding: 0 10px;
  }
}

@media screen and (max-width: 40.625em) {
  .accordion-menu {
    margin: 0 10px;
  }
}

.accordion-menu a {
  color: #87529b;
}

.accordion-menu ul,
.accordion-menu li {
  list-style: none;
}

.accordion-menu label {
  cursor: pointer;
  display: block;
}

.accordion-menu input + label:before {
  padding: 0 10px;
  position: relative;
  top: 4px;
  font-size: 175%;
  line-height: 1;
  font-family: "droid-sans";
  content: '+';
}

.accordion-menu input:checked + label:before {
  content: '-';
  padding: 0 14px;
}

.accordion-menu p {
  margin-bottom: 15px;
  line-height: 1.5;
}

.accordion-menu p:last-of-type {
  margin: 0;
}

.accordion-menu .accordion-top-group {
  background: #87529b;
  display: block;
  padding: 5px;
  margin-bottom: 10px;
  font-family: "droid-sans";
  font-size: 0.75em;
  color: #FFFFFF;
}

.accordion-menu .accordion-sub-group {
  font-size: 0.6875em;
  line-height: 2.5;
  margin-bottom: 10px;
  background: #1a1a1a;
  color: #c2b59b;
  font-family: "droid-sans-regular";
}

.accordion-menu .accordion-body {
  background: #cccccc;
  font-family: "droid-sans-regular";
  font-size: 0.875em;
  padding: 30px 50px;
  margin-bottom: 10px;
  color: #0a0a0a;
}

.accordion-menu .accordion-body ul,
.accordion-menu .accordion-body ol,
.accordion-menu .accordion-body li,
.accordion-menu .accordion-body ul li,
.accordion-menu .accordion-body ol li {
  display: inherit;
  list-style-type: initial !important;
}

.accordion-menu input[type=checkbox] {
  /* hide native checkbox */
  position: absolute;
  opacity: 0;
}

.accordion-menu ul {
  /* by default hide all sub menus */
  display: none;
}

.accordion-menu input[type=checkbox]:checked + label + ul,
.accordion-menu input[type=checkbox]:checked + label:nth-of-type(n) + ul {
  /* show children when item is checked */
  display: block;
}

.embed_pdf,
.embed_iframe {
  padding-bottom: 56.25%;
  position: relative;
  padding-top: 30px;
  height: 842px;
  overflow: hidden;
}

.embed_pdf object,
.embed_pdf iframe,
.embed_iframe object,
.embed_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed_iframe {
  height: 1300px;
}

.content-header {
  display: block;
  text-align: center;
}

.content-header h1 {
  float: left;
}

@media screen and (max-width: 48em) {
  .content-header h1 {
    float: none;
  }
}

.content-header .sfsi_widget {
  float: right;
  margin-right: 141px;
}

@media screen and (max-width: 48em) {
  .content-header .sfsi_widget {
    float: none;
    margin-right: 0;
    width: 130px;
    display: inline-block;
  }
  .content-header .sfsi_widget div:first-child {
    margin-left: 0 !important;
  }
}

.content-header ~ * {
  clear: both;
}

.social-list {
  display: inline-block;
}

@media screen and (max-width: 23.125em) {
  .social-list {
    display: block !important;
  }
}

.social-list li {
  margin-left: 5px;
}

@media screen and (max-width: 23.125em) {
  .social-list li {
    margin: 0 5px;
  }
}

@media screen and (max-width: 48em) {
  .js-menu-expanded {
    overflow-y: scroll;
  }
  .js-menu-expanded body {
    overflow: hidden;
    height: 100%;
  }
}

@media screen and (max-width: 48em) {
  .site-menu {
    height: 0vh;
    transition: height 0.35s ease-out;
  }
  .js-menu-expanded .site-menu {
    width: calc(100% + 50px);
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-right: 50px;
  }
}

@media screen and (max-width: 48em) {
  .main-header {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: background-color 0.35s linear;
  }
  .js-menu-expanded .main-header {
    background-color: #000000;
  }
  .js-menu-expanded .main-header .wrapper {
    overflow: hidden;
  }
  .main-header ~ main {
    padding-top: 100px;
  }
}

@media screen and (max-width: 48em) {
  .main-menu {
    opacity: 0;
    padding-top: 10px;
    padding-bottom: 44px;
    overflow: hidden;
    transition: opacity 0.35s linear;
  }
  .js-menu-expanded .main-menu {
    opacity: 1;
  }
}

@media screen and (max-width: 48em) {
  .main-menu .menu-item {
    opacity: 0;
    transform: scale(1.1) translateY(-100%);
    transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  }
  .main-menu .menu-item:nth-child(1) {
    transition-delay: 300ms, 300ms;
  }
  .main-menu .menu-item:nth-child(2) {
    transition-delay: 350ms, 350ms;
  }
  .main-menu .menu-item:nth-child(3) {
    transition-delay: 400ms, 400ms;
  }
  .main-menu .menu-item:nth-child(4) {
    transition-delay: 450ms, 450ms;
  }
  .main-menu .menu-item:nth-child(5) {
    transition-delay: 500ms, 500ms;
  }
  .main-menu .menu-item:nth-child(6) {
    transition-delay: 550ms, 550ms;
  }
  .js-menu-expanded .main-menu .menu-item {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.main-menu a {
  display: block;
  vertical-align: middle;
  line-height: 0.8;
  text-decoration: none;
  text-align: left;
  padding: 16px;
}

@media screen and (max-width: 48em) {
  .main-menu a {
    border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  }
}

.main-menu {
  list-style: none;
}

@media screen and (min-width: 48.0625em) {
  .main-menu {
    font-size: 0.1px;
    text-align: justify;
  }
  .main-menu:after {
    content: '';
    display: inline-block;
    width: 100%;
  }
  .main-menu > * {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    font-size: 1rem;
  }
}

.main-menu .menu-item {
  color: #000000;
}

.main-menu a {
  color: inherit;
}

.main-menu .sub-menu {
  list-style: none;
}

@media screen and (max-width: 48em) {
  .main-menu .sub-menu {
    display: none;
  }
}

@media screen and (min-width: 48.0625em) {
  .main-menu .sub-menu {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    background-color: #000000;
  }
}

@media screen and (max-width: 48em) {
  .mobile-menu-button {
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 2px;
    right: 15px;
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 48em) {
  .mobile-button-icon {
    display: block;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 3px;
    background-color: #87529b;
    transition: background-color 0ms linear 200ms;
  }
  .mobile-button-icon:before, .mobile-button-icon:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #87529b;
    transform: rotate(0deg);
    transition: top 200ms ease-in-out 150ms, transform 150ms ease-in-out;
  }
  .mobile-button-icon:before {
    top: -7px;
  }
  .mobile-button-icon:after {
    top: calc(100% + 4px);
  }
}

.js-menu-expanded .mobile-button-icon {
  background-color: transparent;
  transition: background-color 0ms linear 200ms;
}

.js-menu-expanded .mobile-button-icon:before, .js-menu-expanded .mobile-button-icon:after {
  top: 0;
  transition: top 200ms ease-in-out, transform 150ms ease-in-out 150ms;
}

.js-menu-expanded .mobile-button-icon:before {
  transform: rotate(45deg);
}

.js-menu-expanded .mobile-button-icon:after {
  transform: rotate(-45deg);
}

.main-menu {
  position: absolute;
  width: calc(100% - 182px);
  letter-spacing: 0.8px;
  padding-left: 10px;
  bottom: 0px;
  right: 0px;
}

.main-menu a {
  font-size: 1.5em;
  color: #FFFFFF;
  line-height: 1.8;
  text-transform: uppercase;
  display: inline;
  padding: 0px;
  transition: color 0.2s ease;
}

.main-menu a:hover {
  color: #87529b;
}

.main-menu li:hover > .sub-menu {
  display: block;
}

@media screen and (max-width: 60.9375em) {
  .main-menu {
    letter-spacing: 0px;
    padding: auto;
  }
  .main-menu li {
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media screen and (max-width: 54.25em) {
  .main-menu a {
    font-size: 1.5em;
  }
  .main-menu li {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (max-width: 48em) {
  .main-menu {
    top: 0px;
    bottom: auto;
  }
}

.sub-menu {
  font-family: "droid-sans";
  background-color: rgba(135, 82, 155, 0.85) !important;
  letter-spacing: 0px;
  display: none;
  padding: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.sub-menu a {
  text-transform: capitalize;
  font-size: 0.75em;
  display: inline;
  padding: 3px;
}

@media screen and (max-width: 48em) {
  .sub-menu a {
    font-size: 0.875em;
  }
}

.sub-menu li {
  display: block;
  width: 100%;
  text-align: left;
  border: 0px;
  border-color: #000000;
  border-bottom: 0.5px solid #000000;
  border-bottom-width: 1px;
}

.sub-menu li:last-of-type {
  border-bottom: 0px;
}

.sub-menu li:before {
  content: '▸';
  display: inline-block;
}

.sub-menu li:hover:before {
  color: #FFFFFF;
}

.sub-menu li a:hover {
  color: #FFFFFF;
}

@media screen and (max-width: 48em) {
  .wrapper,
  .main-header {
    overflow: visible !important;
  }
  .main-menu {
    position: inherit;
    text-decoration: none;
    width: auto;
  }
  .main-menu a {
    border: 0px;
  }
  .site-menu {
    margin-left: -1.625em;
  }
  .js-menu-expanded .site-menu {
    text-align: right;
    background: rgba(0, 0, 0, 0.85);
  }
  .js-menu-expanded .site-menu ul {
    border: 0px;
  }
}

@keyframes slidein-animation {
  0% {
    opacity: 0;
  }
  1% {
    transform: translateX(160px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.horizontal-scroll-menu {
  overflow: hidden;
  height: 44px;
  padding: 4px 0;
}

.horizontal-scroll-menu .horizontal-scroll-menu-items {
  width: 100%;
  font-size: 0.1px;
  white-space: nowrap;
  padding-bottom: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  appearance: none;
  -webkit-overflow-scrolling: touch;
}

.horizontal-scroll-menu .horizontal-scroll-menu-items > .horizontal-scroll-menu-item {
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  padding: 0 5px;
  animation-name: slidein-animation;
  animation-duration: 350ms;
  animation-fill-mode: backwards;
}

.horizontal-scroll-menu .horizontal-scroll-menu-link {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 10px 5px;
}

.content-editor blockquote {
  border-left: 4px solid #000000;
  color: #404040;
  font-size: 1.1875em;
  font-style: italic;
  margin-bottom: 26px;
  padding: 0 0 6px 20px;
}

.content-editor blockquote.alignleft, .content-editor blockquote.alignright {
  border: 0 solid #000000;
  border-top-width: 4px;
  padding: 14px 0 0;
  width: calc(50% - 14px);
}

.content-editor address {
  font-style: italic;
}

.content-editor pre {
  border: solid 1px #bfbfbf;
  line-height: 1.3125;
  max-width: 100%;
  overflow: auto;
  padding: 14px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.content-editor sup,
.content-editor sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.content-editor sub {
  top: -6px;
}

.content-editor sup {
  bottom: -3px;
}

.content-editor small {
  font-size: 80%;
}

.content-editor big {
  font-size: 125%;
}

.content-editor hr {
  background-color: #bfbfbf;
  border: 0;
  height: 1px;
}

.content-editor .alignleft {
  float: left;
  margin: 6px 26px 26px 0;
}

.content-editor .alignright {
  float: right;
  margin: 6px 0 26px 26px;
}

.content-editor .aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 26px;
}

.wp-caption {
  background: transparent;
  border: none;
  margin-bottom: 26px;
  max-width: 100%;
  padding: 0;
  text-align: inherit;
}

.wp-caption-text,
.wp-caption-dd {
  color: #404040;
  font-size: 0.8125em;
  font-style: italic;
  padding-top: 7px;
}

.content-editor > * + * {
  margin-top: 1.625em;
}

.content-editor h1,
.content-editor h2,
.content-editor h3,
.content-editor h4,
.content-editor h5,
.content-editor h6 {
  line-height: 1.1;
}

.content-editor h1 + *,
.content-editor h2 + *,
.content-editor h3 + *,
.content-editor h4 + *,
.content-editor h5 + *,
.content-editor h6 + * {
  margin-top: 0.8125em;
}

.content-editor h1 {
  font-size: 2.625em;
}

.content-editor h2 {
  font-size: 1.625em;
}

.content-editor h3 {
  font-size: 1.25em;
}

.content-editor h4,
.content-editor h5 {
  font-size: 1em;
}

.content-editor h6 {
  font-size: 0.8125em;
}

.content-editor a {
  box-shadow: 0 0 0 0 #87529b;
  color: #87529b;
  text-decoration: none;
  transition: color 0.1s ease, box-shadow 0.1s ease;
}

.content-editor a:hover, .content-editor a:focus {
  color: #5f396d;
  box-shadow: 0 1px 0 0 #5f396d;
}

.content-editor ul,
.content-editor ol {
  list-style: none;
  margin-top: 0;
}

.content-editor ul li,
.content-editor ol li {
  position: relative;
}

.content-editor ul li {
  padding-left: 1.25em;
}

.content-editor ul li:before {
  content: '\2022';
  position: absolute;
  left: 0.4375em;
}

.content-editor ol {
  counter-reset: num-list;
}

.content-editor ol li {
  padding-left: 2em;
}

.content-editor ol li:before {
  counter-increment: num-list;
  content: counter(num-list) ".";
  position: absolute;
  left: 0;
  width: 1.625em;
  text-align: right;
}

.content-editor img {
  max-width: 100%;
  height: auto;
}

.bx-wrapper {
  position: relative;
}

.slide {
  line-height: 0;
}

.slide img {
  width: 100%;
  height: auto;
}

.bx-controls-direction > * {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.bx-controls-direction a {
  text-decoration: none;
  line-height: 0;
  transition: color 0.25s ease;
}

.bx-controls-direction .bx-prev {
  left: 12px;
}

.bx-controls-direction .bx-next {
  right: 12px;
}

.bx-pager {
  font-family: arial;
  font-size: 0.85em;
  font-weight: 700;
  text-align: center;
  line-height: 0;
}

.bx-pager .bx-pager-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-pager .bx-pager-item + .bx-pager-item {
  margin-left: 7px;
}

.bx-pager a {
  background: white;
  text-indent: -9999px;
  display: block;
  width: 12px;
  height: 12px;
  outline: 0;
  border: solid 1px #4d4d4d;
  border-radius: 50%;
  transition: background-color 0.25s ease, border-color 0.25s ease;
}

.bx-pager a:hover, .bx-pager a:focus {
  background: #4d4d4d;
}

.bx-pager a.active {
  background: #000000;
  border-color: #000000;
}

.page-hero {
  position: relative;
}

.bx-pager a {
  background: none;
  border: 2px solid #FFFFFF;
  width: 9px;
  height: 9px;
}

.bx-pager a.active {
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
}

.bxcontrol-wrapper {
  z-index: 5;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1080px;
  background: rgba(0, 255, 0, 0.5);
}

@media screen and (max-width: 48em) {
  .bxcontrol-wrapper {
    top: 50%;
  }
}

.bx-next,
.bx-prev {
  color: transparent;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.slider-next,
.slider-prev {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}

.slider-next svg,
.slider-prev svg {
  width: 23px;
  height: auto;
}

.slider-next {
  right: 0;
}

.slider-next svg {
  transform: rotate(180deg);
}

@media screen and (max-width: 48em) {
  .bx-next {
    margin-right: -35px;
  }
}

@media screen and (max-width: 48em) {
  .bx-prev {
    margin-left: -35px;
  }
}

.bx-default-pager {
  z-index: 2;
  margin-top: -175px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
}

@media screen and (max-width: 48em) {
  .bx-default-pager {
    margin-top: -100px;
  }
}
