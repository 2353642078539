.department-content {
	color: $theme-white;
	background: $theme-black;
	font-family: $font-accent-thin;
	margin-top: 50px;
	padding: em(50px);

	h1 {
		font-family: $font-main;
		text-transform: uppercase;
		color: $theme-purple;
		letter-spacing: 1px;
	}

  p {
  	margin-bottom: 15px;
  }
  p:last-of-type {
  	margin: 0;
  }

  a {
  	color: $link-color;
  }
}

.body-social-list {
	position: absolute;
	right: 74px;
	top: 60px;

	svg {
		width: 20px;
		height: auto;
	}

	li {
		display: inline;
		margin: 5px;
	}

	a {
		color: lighten($theme-grey, 35%);
	}
}