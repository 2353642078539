.site-logo {
  display: inline-block;
  z-index: 2;
  margin-left:11px;
  background: $theme-black;
  padding-left:23px;
  padding-right:23px;
  padding-top:21px;
  padding-bottom:33px;
  box-shadow: 0px 6px 16px 1px rgba($theme-black, 0.5);
  
  .site-logo-sm { display: none }


  img[src*=svg] {
    width: 95px;
    height: 117px;
  }

  @media screen and (max-width: em($mobile-menu-switch-max)){
    box-shadow:none;
    padding: 5px 0 0 0;

    .site-logo-lg { display: none }
    .site-logo-sm { display: block }
    img[src*=svg] {
      height:46px;
    }
  }
}

.site-logo-spotlight {
  position: absolute;
  z-index: -1;
  top: 25px;
  left: 34px;
  height: 95px;
  width: 95px;
  border-radius: 100px;
  box-shadow: 4px 0px 144px 22px rgba($theme-white, 0.5);

  @media screen and (max-width: em($mobile-menu-switch-max)){
    display:none
  }
}