.email-form {
	float: right;
	display: flex;
	// align-content: center;
	// justify-content: center;

	margin-left: 10px;
	@media screen and (max-width:em(570px)){
		float:none;
		margin-left: none;
	}


	form {
		display: flex;
		align-items: center;

		@media screen and (max-width:em(820px)){
			display: block;
			& > * {
				display: block;
			}
		}
	}

	li {
		display: block;
	}

	input[type="text"] {
		border-radius: 50px;
		padding-left: 70px;
		padding-right: 70px;
		padding-top: 5px;
		padding-bottom: 5px;
		border:0px;
		text-transform: uppercase;
		// text-align:center;
		font-size: rem(12px);
		font-family: $font-accent;
		color: $theme-purple;

		@media screen and (max-width:em(570px)){
			margin-left: 0px;
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.gform_heading {
		margin-right: 17px;

		@media screen and (max-width:em(820px)){
			margin-right: 0;
		}
	}

	.gfield_checkbox {
		display: inline-block;

		input {
			width: auto;
			appearance: none;
			background: $theme-white;
			border-radius: 5px;
			box-sizing: border-box;
			position: relative;
			width: 10px;
			height: 10px;
			border-width: 0;

			&:checked {
 				background: url('../../images/icon-check.svg') no-repeat;
 				background-size: 15px;
 				background-color: $theme-white;
 				background-position: 50%;
			}
		}

		li {
			display: inline-flex;
			// width: 100px;

			label {
				font-size: em(8px);
				margin: 0 10px;
			}
		}
	}

	.gform_title {
		color: $theme-white;
		font-size: em(16px);
	}

  .gfield_label,
  .gform_footer {
    display:none;
  }
  .gform_body {
    position: relative;
    top: -5px;

    input[type="text"] {
      background: url('../../images/icon-email.svg') no-repeat scroll 55px 50%;
      background-size: 13px;
      background-color: $theme-white;

      @media screen and (max-width: em(570px)){
      	background: $theme-white;
      	text-align: center;
      }
    }
  }
}