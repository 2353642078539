@keyframes slidein-animation {
  0% {
    opacity: 0;
  }

  1% {
    transform: translateX(160px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.horizontal-scroll-menu {
  @extend %clearfix;
  overflow: hidden;
  height: 44px;
  padding: 4px 0;

  .horizontal-scroll-menu-items {
    width: 100%;
    font-size: 0.1px;
    white-space: nowrap;
    padding-bottom: 50px;
    overflow-x: auto;
    overflow-y: hidden;
    appearance: none;
    -webkit-overflow-scrolling: touch;

    & > .horizontal-scroll-menu-item {
      display: inline-block;
      font-size: 1rem;
      line-height: 1;
      padding: 0 5px;
      animation-name: slidein-animation;
      animation-duration: 350ms;
      animation-fill-mode: backwards;
    }
  }

  .horizontal-scroll-menu-link {
    display: block;
    color: inherit;
    text-decoration: none;
    padding: 10px 5px;
  }
}
