//Hide all the honeypots
#field_1_7,
#field_2_12,
#field_3_6,
#field_4_3,
#field_5_3 {
	display: none;
}

.gform_title {
	font-family: $font-main;
	font-weight: normal;
	color: $theme-purple;
	text-transform: uppercase;
	font-size: em(36px);
}

.gform_wrapper {
	font-family: $font-accent;

	input[type=submit] {
		@extend %form-button;
	}

	textarea,
	input,
	select,
	textarea {
		width: 100%;
		border-radius: 5px;
		padding: 10px;
		text-transform: uppercase;
	}


	select {
		-webkit-appearance: none;
	  -moz-appearance: none;
	  appearance: none;
	  cursor: pointer;
	  color: $theme-purple;
	  background: url(../../images/icon-drop-down.svg) no-repeat;
	  background-position: 97%;
	  background-size: 20px;
	  background-color: $theme-white;
	}

	textarea {
		height: 263px;
	}
}

.ui-datepicker {
	background: $theme-white;
	display: none;
	font-size: em(20px);
	border: 2px solid $theme-lt-grey;
	border-radius: 5px;
	padding: 20px;

	.ui-datepicker-next {
		float: right;
	}

	a {
		color: $theme-purple;
		text-decoration: none;
	}

	table * {
		margin: 5px;
	}
}

.gform_confirmation_wrapper {
	text-align: center;
	font-size: em(24px);
}