.contact-us-form {
	width: 100%;
	// float: right;
	// margin: 0 auto;
	box-sizing: border-box;
	border-radius: 10px;
	position: relative;
	z-index:5;

	@media screen and (max-width:em($mobile-menu-switch-max)){
		width: 100%;
		float: none;
	}

	input,
	select,
	textarea{
		margin:0 0 15px 0;
	}

	textarea {
		height: 190px;
	}

	.gform_title,
	.gform_description,
	label {
		display: none;
	}

	.gform_footer{
		float: right;
		width: 50%;
		padding-left: 10px;
	}

	li:first-of-type {
		width: 100%;
		span {
			display: inline-block;
			width: 50%;
		}
		span:first-of-type {
			float: left;
			padding-right: 10px;
		}
		span:last-of-type {
			float: right;
			padding-left: 10px;
		}

		&:after {
			content: '';
			display: block;
			clear: both;
		}
	}

}