.standard-content {
	color: $theme-white;
	background: $theme-black;
	font-family: $font-accent;
	margin-top: 50px;
	padding: em(50px);
	overflow: auto;

	h1 {
		font-family: $font-main;
		text-transform: uppercase;
		color: $theme-purple;
    font-size: em(36px);
		letter-spacing: 1px;

    @media screen and (max-width:em($mobile-menu-switch-max)){
      text-align: center;
    }
	}

  @media screen and (max-width:em($mobile-menu-switch-max)){

  }

  p {
  	margin-bottom: 15px;
  	font-family: $font-accent-thin;
  	// display: inline-block;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.contact-us-content {
  .callouts {
    width: 50%;

    @media screen and (max-width:em(840px)){
      width:100%;
    }
  }

  .contact-callout {
    width: 47%;
    display: inline-block;
    margin-right: 10px;

    .title {
      display: block;
    }

    p { 
      width: 100%; 
      position: static !important; 
      padding: 0 !important;
    }

    @media screen and (max-width:em($mobile-menu-switch-max)){
      width: 100%;
    }
  }

  p {
    width: 50%;

    &:last-of-type {
      float: right;
      position: relative;
      top: -180px;
      padding-left: 10px;

      @media screen and (max-width:em(840px)){
        position: static;
        float: none;
        padding: 0;
      }
    }

    @media screen and (max-width:em(840px)){
      width: 100%;
    }
  }

  img {
    width: 100%;
    // float: right;
  }

  .contact-us-form {
    top: -70px;
    @media screen and (max-width:em(840px)){
      top: 0;
    }
  }

}

.page-id-38 .department-form {
  margin-top: -200px;
  @media screen and (max-width:em(845px)){
    margin-top: 0;
  }
}
.holiday-market-select-content {
  // margin-bottom: -300px;

  p { display: block; }

  section {
    margin-bottom: 20px;
  }

  p:last-of-type {
    padding: 0;
    // width: 450px;
    position: relative;
    top: -180px;
    float: right;
    right: 20%;
    img {
      width: 100%;
      height: auto;
    }
    @media screen and (max-width:em(1033px)){
      right: 0;
    }
    @media screen and (max-width:em(845px)){
      position: static;
      float: none;
    }
  }

  @media screen and (max-width:em(845px)){
    margin-bottom: 0;
  }

  @media screen and (max-width:em($mobile-menu-switch-max)){
    p:nth-of-type(2),
    p:nth-of-type(3),
    p:last-of-type {
      width:100%;
      text-align: center;
    }
  }
}

.cubeside-content {
  position: relative;
  top: -392px;
  padding-top: 90px;
}

.content-404 {
  margin-top: 208px;
  padding: 70px;
  background: $theme-black;
}