.main-menu {
  list-style: none;

  @media screen and (min-width: em($mobile-menu-switch-min)) {
    @include fluid-grid();
  }

  .menu-item {
    color: $main-menu-item-color;
  }
  a {
    color: inherit;
  }

  .sub-menu {
    list-style: none;

    @media screen and (max-width: em($mobile-menu-switch-max)) {
      display: none;
    }
    @media screen and (min-width: em($mobile-menu-switch-min)) {
      position: absolute;
      z-index: 1;
      white-space: nowrap;
      background-color: $main-header-background-color;
    }
  }
}


.mobile-menu-button {
  @extend %hidden;

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 2px;
    right: 15px;
    width: 48px;
    height: 48px;
  }
}
.mobile-button-icon {

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    display: block;
    position: absolute;
    top: #{(48/2)}px;
    left: 50%;
    transform: translateX(-50%);
    width: 25px;
    height: 3px;
    background-color: $mobile-menu-button-color;
    transition: background-color 0ms linear 200ms;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $mobile-menu-button-color;
      transform: rotate(0deg);
      transition: top 200ms ease-in-out 150ms, transform 150ms ease-in-out;
    }
    &:before {
      top: -7px;
    }
    &:after {
      top: calc(100% + 4px);
    }
  }

  .js-menu-expanded & {
    background-color: transparent;
    transition: background-color 0ms linear 200ms;

    &:before,
    &:after {
      top: 0;
      transition: top 200ms ease-in-out, transform 150ms ease-in-out 150ms;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.main-menu {
  position:absolute;
  width: calc(100% - 182px);
  letter-spacing: 0.8px;
  padding-left:10px;
  bottom:0px;
  right:0px;
  a{
    font-size: em(24px);
    color: $theme-white;
    line-height: 1.8;
    text-transform: uppercase;
    display:inline;
    padding:0px;
    transition: color 0.2s ease;

    &:hover{
      color:$theme-purple;
    }
  }
  
  li {
    &:hover {
      > .sub-menu { display: block; }
    }
  }

  @media screen and (max-width:em(975px)){
    letter-spacing: 0px;
    padding: auto;
    li {
      padding-left:5px;
      padding-right:5px;
    }
  }
  
  @media screen and (max-width:em(868px)){
    a {
      font-size: em(24px);
    }
    li {
      padding-left:10px;
      padding-right:10px;
    }
  }

  @media screen and (max-width: em($mobile-menu-switch-max)){
    top:0px;
    bottom:auto;
  }

}

.sub-menu {
  font-family: $font-accent;
  background-color: rgba($theme-purple, 0.85) !important;
  letter-spacing: 0px;
  display:none;
  padding:13px;
  padding-top:8px;
  padding-bottom:8px;

  a {
    text-transform: capitalize; 
    font-size: em(12px);
    display:inline;
    padding:3px;

    @media screen and (max-width:em($mobile-menu-switch-max)){
      font-size: em(14px);
    } 
  }
  
  li {
    display:block;
    width:100%;
    text-align: left;
    border: 0px;
    border-color: $theme-black;
    border-bottom: 0.5px solid $theme-black;
    border-bottom-width: 1px;

    &:last-of-type {
      border-bottom:0px;
    }

    &:before {
      content: '▸';
      display: inline-block;        
    }

    &:hover:before {
      color: $theme-white;
    }

    & a:hover{
      color: $theme-white;
    }
  }
}

//mobile menu overrides

@media screen and (max-width: em($mobile-menu-switch-max)){
  .wrapper,
  .main-header { 
    overflow:visible !important;
  }
  .main-menu { 
    position: inherit; 
    text-decoration: none;
    width: auto;
    a {
      border: 0px;
    }
  }

  .site-menu {
    margin-left: em(-26px);
  }

  .js-menu-expanded .site-menu {
    text-align:right;
    background: rgba($theme-black, 0.85);
  
    ul { 
      border: 0px;
    }
  }
}
